<!-- <div *ngFor="let sharingGroup of filteredSharingGroups">
    {{sharingGroup.name}}
</div> -->

<div class="location-container">

    <div class="search">
        <span><i class="far fa-search"></i></span>
        <input type="text" name="Location search" (keyup)="applyFilter($event.target.value)" placeholder="Search by location name or address..." autocomplete="off" [disabled]="disabled">
    </div>

    <mat-selection-list #locations disableRipple="true" (selectionChange)="onSelectionChange($event.option.value, $event.option.selected)" multiple [disabled]="disabled">

        <!-- All Locations -->
        <mat-list-option #allLocations class="all-locations-option" value="locations:*" [selected]="locationForm?.controls['locations'].value?.includes('*')">
            <h4 matLine>All Locations</h4>
        </mat-list-option>

        <ng-container *ngFor="let sharingGroup of filteredSharingGroups">

            <!-- Sharing Group Name -->
            <mat-list-option #sharingGroupSelector class="sharing-group-option" [value]="'sharingGroups:' + sharingGroup.groupId" [selected]="allLocations.selected || locationForm?.controls['sharingGroups'].value?.includes(sharingGroup.groupId)" [disabled]="allLocations.selected">
                <h4 matLine>{{sharingGroup.name}}</h4>
            </mat-list-option>

            <!-- Locations -->
            <mat-list-option *ngFor=" let location of filteredLocations | locationInSharingGroup:sharingGroup" class="location-option" [value]="'locations:' + location.locationId" [selected]="allLocations.selected || sharingGroupSelector.selected || locationForm?.controls['locations'].value?.includes(location.locationId)" [disabled]="allLocations.selected || sharingGroupSelector.selected">
                <h4 matLine>{{location.data.name}}</h4>
                <span matLine class="address">{{location.data.address}}, {{location.data.city}}, {{location.data.prov}}, {{location.data.country}}</span>
            </mat-list-option>

        </ng-container>

        <!-- Unsorted Header -->
        <mat-list-item class="sharing-group-option" [disabled]="allLocations.selected">
            <h4 matLine>Independent Locations</h4>
        </mat-list-item>

        <!-- Unsorted -->
        <mat-list-option *ngFor="let location of filteredLocations | locationInSharingGroup:filteredSharingGroups:true" class="location-option" [value]="'locations:' + location.locationId" [selected]="allLocations.selected || locationForm?.controls['locations'].value?.includes(location.locationId)" [disabled]="allLocations.selected">
            <h4 matLine>{{location.data.name}}</h4>
            <span matLine class="address">{{location.data.address}}, {{location.data.city}}, {{location.data.prov}}, {{location.data.country}}</span>
        </mat-list-option>

    </mat-selection-list>

</div>