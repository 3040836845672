import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MaterialModule } from "@shared/material.module";
import { CoreModule } from "@core/core.module";
import { ClientsService } from "@admin/clients/clients.service";
import { FeaturesService } from "@admin/clients/features.service";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// FEATURE MODULES
import { AlertsModule } from "@alerts/alerts.module";

//Providers
import { ProductsService } from "@products/products.service";
import { ProductsStorageService } from "@products/products.storage.service";
import { DirectSalesService } from "@products/product/direct-sales/direct-sales.service";
import { SubscriptionSalesService } from "@products/product/subscriptions/subscription-sales.service";
import { LoyaltyService } from "@financial/loyalty/loyalty.service";
import { ThirdPartyService } from "@hardware/third-party/thirdParty.service";
import { ControllersService } from "@hardware/cwc-controllers/controllers.service";
import { UserService } from "@admin/users/user.service";
import { UserReportService } from "@reports/userReport.service";
import { RoleService } from "@admin/users/roles/role.service";
import { LocationAccessService } from "@admin/users/users-list/edit-user/locationAccess.service";
import { LocationsService } from "@locations/locations.service";
import { LoyaltyLogService } from "@admin-logs/loyaltyLog.service";
import { WhiteboxLogService } from "@admin-logs/whiteboxLog.service";
import { TransactionsService } from "@locations/location/transactions/transactions.service";
import { SharingService } from "@locations/sharing-groups/sharing.service";
import { FulfillmentService } from "@locations/fulfillment.service";
import { ReportsService } from "@reports/reports.service";
import { CustomerTransactionService } from "@customers/customer-transaction.service";
import { CustomFieldsService } from "@admin/custom-fields/custom-fields.service";
import { CustomDataService } from "@customers/details/modal-custom-data/custom-data.service";
import { LayoutGapStyleBuilder } from '@angular/flex-layout';
import { CustomLayoutGapStyleBuilder } from '@core/services/custom-layout-gap-style-builder';
import { StorageModule } from '@ngx-pwa/local-storage';
import { TaxService } from "@financial/taxes/taxes.service";
import { ProcessingService } from "@financial/payment-processing/processing.service";
import { CommonModule } from "@angular/common";
import { FraudMitigationService } from "@admin/fraud-mitigation/fraud-mitigation.service";
import { DashboardService } from "@dashboard/dashboard.service";
import { SettingsService } from "./settings/settings.service";

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        CoreModule,
        AlertsModule,
        StorageModule.forRoot({ IDBNoWrap: false }),
    ],
    providers: [
        Title,
        ClientsService,
        FeaturesService,
        LoyaltyService,
        LocationsService,
        LoyaltyLogService,
        WhiteboxLogService,
        TransactionsService,
        SharingService,
        ThirdPartyService,
        ControllersService,
        ProductsService,
        ProductsStorageService,
        DirectSalesService,
        SubscriptionSalesService,
        UserService,
        UserReportService,
        RoleService,
        ReportsService,
        FulfillmentService,
        CustomerTransactionService,
        LocationAccessService,
        CustomFieldsService,
        CustomDataService,
        TaxService,
        ProcessingService,
        { provide: LayoutGapStyleBuilder, useClass: CustomLayoutGapStyleBuilder },
        FraudMitigationService,
        DashboardService,
        SettingsService
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
